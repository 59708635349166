import { MAINTAIN_PERMISSION, WEB_ANALYTICS_CLUSTERGROUPL1 } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Cluster Group Mapping L1 - Group Channel", "search-by": "ClusterGroup,GroupChannel,GroupChannelDesc", "ph-search-by": "Search by keywords", "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Move")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "swap", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "GroupChannel", attrs: { "data-index": "groupChannel", "title": "Group Channel", "sorter": true, "parse": _vm.parseGroupChannel } }), _c("text-field", { key: "ClusterGroup", attrs: { "data-index": "clusterGroup", "title": "Cluster Group", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListClusterGroupMappingL1",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  methods: {
    parseGroupChannel(value, record) {
      if (!value)
        return "";
      return `${value} - ${record.groupChannelDesc}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListClusterGroupMappingL1 = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-cluster-group-mappingL1" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.cluster-l1-trans", "api-url": _vm.apiUrl, "list": _vm.ListClusterGroupMappingL1, "edit-route": "/web-analytics/cluster-l1-trans/:id", "page": _vm.page } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ClusterGroupMappingL1",
  data() {
    return {
      ListClusterGroupMappingL1,
      page: WEB_ANALYTICS_CLUSTERGROUPL1,
      apiUrl,
      itemsMenu: [
        {
          key: "clustermapping",
          title: "Cluster Mapping",
          path: ""
        },
        {
          key: "clustergroupmappinl1",
          title: "Cluster Group Mapping L1",
          path: "/web-analytics/cluster-l1-trans"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
